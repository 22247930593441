<template lang="pug">
main
  navigation
  section#news
    h3.category 新聞與活動
    .divider
    //-  youtube embed
    .embed-responsive.embed-responsive-16by9.ratio.ratio-16x9
      iframe#player.embed-responsive-item(
        src="https://www.youtube.com/embed/9PV8zPzZqBI",
        allowfullscreen=""
      )
    //- title
    h1.title 接見金舶獎得主 總統：一起守疫情、攜手拚振興
    h2.subtitle 總統接見「第三屆品牌金舶獎得獎企業」
      //- 新聞內容
    .row
      .main-content.col-md-8
        .time 中華民國110年09月23日
        p
          | 蔡英文總統今（23）日上午接見「第三屆品牌金舶獎得獎企業」時表示，政府將推出五倍券，她期盼政府加民間，「一起守疫情，攜手拚振興」，一起來刺激買氣、振興臺灣的經濟。
        p 總統致詞時首先歡迎大家前來總統府，並恭喜得獎企業從激烈競爭中脫穎而出，成為第三屆金舶獎得主，也是企業創新、永續經營的典範。
        p
          | 總統提到，像是成真咖啡董事長王國雄，在疫情期間不減薪、不縮編，把盈餘先留給員工，也持續捐助非洲鑿井，讓當地民眾有乾淨的水喝。王董事長不只做到企業的永續經營，也對國際關懷工作有許多貢獻。
        p.focus
          | 總統接著說，這幾年，政府積極推動「2030雙語國家政策」，相信
          span.point 六書堂數位學習執行長吳政哲
          | ，也可以與大家分享很多經驗。
          span.point 六書堂數位學習集團
            | 的線上教學平台，
          span.bottom-line 不僅打造了全英語的學習環境，也幫助偏鄉和育幼院的孩童，能夠在線上免費學習英語，讓英語教育在臺灣更加普及。
        p
          | 總統也提到，另外一個政府很重視的發展戰略，就是新南向政策。景發鋁業在經營越南市場上，表現非常亮眼，他們的努力也讓臺灣優質的鋁製品，在東南亞市場打出了一片天。
        p
          | 總統說，今天因為時間的關係，無法一一介紹所有得獎企業的精采故事，大家在業界占有一席之地，也非常關心臺灣的發展，現在國內的疫情已經比較穩定，接下來很重要的，就是要振興經濟。
        p
          |總統指出，去年政府推出三倍券，鼓勵大家消費，帶動的效益超過1,000億；現場法布甜及泰晶殿，去年也配合振興、推出加碼。政府加民間，確實對刺激消費很有幫助。
        p
          | 總統進一步表示，昨天開始，社群最熱門的話題之一，就是五倍券；今天早上她問了一下，已經超過250萬人完成數位綁定，可見大家對五倍券都很期待。藉此機會，她也想拜託大家，政府加民間，「一起守疫情，攜手拚振興」，一起來推出優惠、刺激買氣，把經濟振興做起來。
        p 最後，總統再次恭喜現場得獎者，也期待大家在各自領域繼續努力，創造更強的競爭力。
        p
          | 訪賓一行由中華民國全國商業總會理事長許舒博率領，經濟部次長陳正祺陪同，前來總統府晉見總統，總統府秘書長李大維也在座。
        .news-source.desktop  新聞資料來源&emsp;
          a(href="https://www.president.gov.tw/NEWS/26208#", target="_blank") 中華民國 總統府官網

        //- 側邊圖片
      aside.gallery.col-md-4

        .portfolio(v-for="(img,index) in gallery" :key="img.path")
          .divider
          .photo
            img(:src="img.path", alt="資料圖片")
            button.zoom(type="button", @click="openModal(index)")
              img(:src="require('@/assets/golden-ship-award/zoom-btn.svg')", alt="放大按鈕")

    .news-source.mobile 新聞資料來源&emsp;
      a(href="https://www.president.gov.tw/NEWS/26208#", target="_blank") 中華民國 總統府官網

  lioshutanFooter

  .gallery-modal(v-if="modal" @click.stop="modal = false")
    .slide-container
      button(type="button",@click.stop="modal = false", class="modal-close" )
        img(:src="require('@/assets/golden-ship-award/close-btn.svg')")
      .slide()
        img(@click.stop="modal = true",:src="gallery[galleryIndex].path")
        p 總統接見「第三屆品牌金舶獎得獎企業」
</template>

<script>
// component
import navigation from '@/views/home/navigation/index.vue';
import lioshutanFooter from '@/components/footer/index.vue';

// css
import 'bootstrap/dist/css/bootstrap.css';

export default {
  name: 'GoldenShipAward',

  components: { navigation, lioshutanFooter },

  data(){
    return {
      modal: false,
      galleryIndex: 0,
      gallery: [
        { path: require('@/assets/golden-ship-award/photo-1.jpg') },
        { path: require('@/assets/golden-ship-award/photo-2.jpg') },
        { path: require('@/assets/golden-ship-award/photo-3.jpg') },
        { path: require('@/assets/golden-ship-award/photo-4.jpg') },
      ],
    };
  },

  watch: {
    modal: function(newValue){
      const body = document.querySelector('body');
      newValue ? body.classList.add('modal-open') : body.classList.remove('modal-open');
    },
  },

  methods: {
    openModal(galleryIndex){
      this.galleryIndex = Number(galleryIndex);
      this.modal = true;
    },
  },

};

</script>

<style scoped>

main{
  background-color:  #FFFFFF;
}

/* mobile first */
section#news {
  padding: 17px 35px 40px;

  letter-spacing: 1px;

  --maim-color: #158a81;
  --text-black: #000;
}

#news .category {
  margin: 0;
  color: #777676;

  font-size: 24px;
  line-height: 32px;

  font-weight: 400;
}

#news>.divider {
    margin: 10px 0 14px;

    background-color: var(--maim-color);
    height: 4px;
}

#news>.title {
    margin: 41px 0 0px;

    color: var(--text-black);
    font-size: 22.528px;
    line-height: 32px;

    font-weight: 700;
}

#news>.subtitle {
    margin: 11.06px 0 0;

    color: var(--maim-color);
    font-size: 21.12px;
    line-height: 28px;

    font-weight: 700;
}

#news .time {
    margin: 10px 0;

    color: var(--text-black);

    font-size: 17.304px;
    line-height: 26px;

    font-weight: 400;
}

/* 新聞內容 */
#news .main-content p {
    /* reset */
    margin: 0;

    font-size: 16.896px;
    line-height: 30px;

    font-weight: 400;
}

#news .main-content p:nth-of-type(n+2) {
    margin: 10px 0 0;
}

#news .main-content p:last-of-type {
    margin-bottom: 37.73px;
}

/* 新聞段落 */
.main-content p.focus {
    border-radius: 10px;
    padding: 14px;

    font-size: 16.896px;
    line-height: 30px;

    background-color: #E3F5F6;
}

.main-content span.point {
    color: var(--maim-color);
    font-weight: 700;
}

.main-content span.bottom-line {
    text-decoration: solid #FFF494 underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 5px;
}

/* 新聞圖片 */
.gallery .portfolio {
    position: relative;
}

.gallery .portfolio:nth-last-child(n+2) {
    margin-bottom: 44.5px;
}

.gallery .portfolio .divider {
    margin-bottom: 16.39px;
    height: 1px;

    background-color: #D4D4D4;

}

.gallery .photo img {
    max-width: 100%;
}

.gallery .photo {
    position: relative;
}

/* 圖片遮罩 */
.gallery .photo:hover::after {
    opacity: 1;
}

.gallery .photo::after {
    content: '';

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background-color: rgba(45, 45, 45, 0.6);

    transition: opacity 0.65s ease;
    opacity: 0;
}

/* 加號按鈕 */
/* reset */
button{
  border: none;
  background: none;
}

.portfolio .zoom {
    position: absolute;

    transform: translate(-50%, -50%);

    top: 50%;
    left: 50%;

    opacity: 0;
    transition: filter 0.35s ease-out, opacity 0.35s ease;
}

.gallery .photo:hover .zoom {
    opacity: 1;
    z-index: 1;
}

.gallery .portfolio .zoom:hover {
    filter: brightness(0.65);
}

/* 資料來源 */
#news .news-source {
    margin-top: 26.16px;
    padding: 10px;
    border-radius: 5px;

    background-color: #F5F5F5;

    color: var(--text-black);
    font-size: 16.9px;
    line-height: 35px;

    font-weight: 400;
}

#news .news-source a {
    color: var(--maim-color);
    text-decoration: underline;
}

#news .news-source a:hover {
    filter: brightness(0.4);
}

#news .news-source.desktop {
    display: none;
}

/* 圖片預覽 modal */
.gallery-modal{
  overflow-y: scroll;

  position: fixed;
  z-index: 1030;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 30, 30, 0.87);
}

.slide-container{
  height: 100%;
  position: relative;
  padding: 20px;
}

.modal-close{
  position: absolute;
  top: 20px;
  right: 20px;
}
.modal-close:hover{
  filter: brightness(0.5) blur(10px);
}

.slide{
  height: 100%;

  flex-direction: column;

  display: flex;
  justify-content: center;
  align-items: center;
}

.slide img{
  max-width: 90%;
}

.slide p{
margin-top: 15px;
color: white;
}

@media (min-width: 768px) {
    section#news {
        padding: 30px 27px 56.31px;
    }

    #news .row {
        padding: 15px;
    }

    #news .category {
        font-size: 32px;
        line-height: 43px;
    }

    #news>.divider {
        margin: 14px 0 24px;
    }

    #news>.subtitle {
        margin: 28px 0 0;

        font-size: 21.12px;
        line-height: 20px;
    }

    #news .time {
        margin: 30px 0;
    }

    /* 新聞內容 */
    #news .main-content p:nth-of-type(n+2) {
        margin: 30px 0 0;
    }

    .main-content p.focus {
        padding: 20px;
    }

    /* 新聞圖片 */
    .gallery .portfolio:nth-last-child(n+2) {
        margin-bottom: 42.77px;
    }

    .gallery .portfolio .divider {
        margin-bottom: 15.66px;
    }

    /* 資料來源 */
    #news .news-source.mobile {
        display: none;
    }

    #news .news-source.desktop {
        display: block;
    }
}

/* desktop */
@media (min-width: 1280px) {

    section#news {
        max-width: 1340px;
        margin: 0 auto;

        padding: 30px 0 47.14px;
    }

    #news .category {
        font-size: 49.28px;
        line-height: 69px;
    }

    #news>.divider {
        margin: 10px 0 20px;
    }

    #news>.title {
        margin: 66.49px 0 0;
    }

    #news>.subtitle {
        margin: 40px 0 0;
    }

    #news .time {
        margin: 18px 0;

        font-size: 18.304px;
    }

    /* 新聞內容 */
    #news .main-content p:nth-of-type(n+2) {
        margin-top: 18px;
    }

    /* 新聞圖片 */
    aside.gallery {
        max-width: 370px;
    }
}
</style>
