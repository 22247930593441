var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('navigation'), _c('section', {
    attrs: {
      "id": "news"
    }
  }, [_c('h3', {
    staticClass: "category"
  }, [_vm._v("新聞與活動")]), _c('div', {
    staticClass: "divider"
  }), _vm._m(0), _c('h1', {
    staticClass: "title"
  }, [_vm._v("接見金舶獎得主 總統：一起守疫情、攜手拚振興")]), _c('h2', {
    staticClass: "subtitle"
  }, [_vm._v("總統接見「第三屆品牌金舶獎得獎企業」")]), _c('div', {
    staticClass: "row"
  }, [_vm._m(1), _c('aside', {
    staticClass: "gallery col-md-4"
  }, _vm._l(_vm.gallery, function (img, index) {
    return _c('div', {
      key: img.path,
      staticClass: "portfolio"
    }, [_c('div', {
      staticClass: "divider"
    }), _c('div', {
      staticClass: "photo"
    }, [_c('img', {
      attrs: {
        "src": img.path,
        "alt": "資料圖片"
      }
    }), _c('button', {
      staticClass: "zoom",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.openModal(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require('@/assets/golden-ship-award/zoom-btn.svg'),
        "alt": "放大按鈕"
      }
    })])])]);
  }), 0)]), _vm._m(2)]), _c('lioshutanFooter'), _vm.modal ? _c('div', {
    staticClass: "gallery-modal",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.modal = false;
      }
    }
  }, [_c('div', {
    staticClass: "slide-container"
  }, [_c('button', {
    staticClass: "modal-close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.modal = false;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/golden-ship-award/close-btn.svg')
    }
  })]), _c('div', {
    staticClass: "slide"
  }, [_c('img', {
    attrs: {
      "src": _vm.gallery[_vm.galleryIndex].path
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.modal = true;
      }
    }
  }), _c('p', [_vm._v("總統接見「第三屆品牌金舶獎得獎企業」")])])])]) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "embed-responsive embed-responsive-16by9 ratio ratio-16x9"
  }, [_c('iframe', {
    staticClass: "embed-responsive-item",
    attrs: {
      "id": "player",
      "src": "https://www.youtube.com/embed/9PV8zPzZqBI",
      "allowfullscreen": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content col-md-8"
  }, [_c('div', {
    staticClass: "time"
  }, [_vm._v("中華民國110年09月23日")]), _c('p', [_vm._v("蔡英文總統今（23）日上午接見「第三屆品牌金舶獎得獎企業」時表示，政府將推出五倍券，她期盼政府加民間，「一起守疫情，攜手拚振興」，一起來刺激買氣、振興臺灣的經濟。")]), _c('p', [_vm._v("總統致詞時首先歡迎大家前來總統府，並恭喜得獎企業從激烈競爭中脫穎而出，成為第三屆金舶獎得主，也是企業創新、永續經營的典範。")]), _c('p', [_vm._v("總統提到，像是成真咖啡董事長王國雄，在疫情期間不減薪、不縮編，把盈餘先留給員工，也持續捐助非洲鑿井，讓當地民眾有乾淨的水喝。王董事長不只做到企業的永續經營，也對國際關懷工作有許多貢獻。")]), _c('p', {
    staticClass: "focus"
  }, [_vm._v("總統接著說，這幾年，政府積極推動「2030雙語國家政策」，相信"), _c('span', {
    staticClass: "point"
  }, [_vm._v("六書堂數位學習執行長吳政哲")]), _vm._v("，也可以與大家分享很多經驗。"), _c('span', {
    staticClass: "point"
  }, [_vm._v("六書堂數位學習集團的線上教學平台，")]), _c('span', {
    staticClass: "bottom-line"
  }, [_vm._v("不僅打造了全英語的學習環境，也幫助偏鄉和育幼院的孩童，能夠在線上免費學習英語，讓英語教育在臺灣更加普及。")])]), _c('p', [_vm._v("總統也提到，另外一個政府很重視的發展戰略，就是新南向政策。景發鋁業在經營越南市場上，表現非常亮眼，他們的努力也讓臺灣優質的鋁製品，在東南亞市場打出了一片天。")]), _c('p', [_vm._v("總統說，今天因為時間的關係，無法一一介紹所有得獎企業的精采故事，大家在業界占有一席之地，也非常關心臺灣的發展，現在國內的疫情已經比較穩定，接下來很重要的，就是要振興經濟。")]), _c('p', [_vm._v("總統指出，去年政府推出三倍券，鼓勵大家消費，帶動的效益超過1,000億；現場法布甜及泰晶殿，去年也配合振興、推出加碼。政府加民間，確實對刺激消費很有幫助。")]), _c('p', [_vm._v("總統進一步表示，昨天開始，社群最熱門的話題之一，就是五倍券；今天早上她問了一下，已經超過250萬人完成數位綁定，可見大家對五倍券都很期待。藉此機會，她也想拜託大家，政府加民間，「一起守疫情，攜手拚振興」，一起來推出優惠、刺激買氣，把經濟振興做起來。")]), _c('p', [_vm._v("最後，總統再次恭喜現場得獎者，也期待大家在各自領域繼續努力，創造更強的競爭力。")]), _c('p', [_vm._v("訪賓一行由中華民國全國商業總會理事長許舒博率領，經濟部次長陳正祺陪同，前來總統府晉見總統，總統府秘書長李大維也在座。")]), _c('div', {
    staticClass: "news-source desktop"
  }, [_vm._v(" 新聞資料來源 "), _c('a', {
    attrs: {
      "href": "https://www.president.gov.tw/NEWS/26208#",
      "target": "_blank"
    }
  }, [_vm._v("中華民國 總統府官網")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "news-source mobile"
  }, [_vm._v("新聞資料來源 "), _c('a', {
    attrs: {
      "href": "https://www.president.gov.tw/NEWS/26208#",
      "target": "_blank"
    }
  }, [_vm._v("中華民國 總統府官網")])]);

}]

export { render, staticRenderFns }